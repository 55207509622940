.login {
  background-color: #fff;
  width: 100%;
  height: 100%;
}

.bg_img {
  /* width: 65%;
    z-index: 0;
    margin-top: 120px; */
  height: 600px;
  width: 700px;
  margin-right: 90px;
}

h1 {
  color: #fff;
}

.login_card {
  display: flex;
  background: #fff;
  position: absolute;
  right: 3%;
  top: 8%;
  bottom: 5%;
  left: 74%;
  align-items: center;
  align-content: center;
  justify-content: center;
  border-radius: 30px;
  z-index: 5;
  flex-direction: column;
}

.login_button {
  background: white;
  border: #22a7cb 2px solid;
  display: flex;
  justify-content: center;
  width: 250px;
  height: 45px;
  box-sizing: border-box;
  box-shadow: 2px 4px 17px -5px rgb(0 0 0 / 20%);
  border-radius: 9px;
  text-align: center;
}
.login_button:hover {
  cursor: pointer;
}

img.login_img {
  width: 45px;
  height: 45px;
}

p.login_p {
  margin: auto;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  color: #22a7cb;
}

.login_p1 {
  position: absolute;
  left: 10.68%;
  top: 8.83%;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 72px;
  letter-spacing: 0.5px;
  color: #ffffff;
  width: 50%;
  z-index: 5;
}

.App1 {
  font-family: Mulish;
  background-color: #fcfcff;
}
.login-container {
  display: flex;
  justify-content: center;
  /* flex-direction: column; */
  align-items: center;
  height: 100vh;
  background-color: #ffc303;
}

.logo-img {
  height: 500px;
}
.btn-block-container {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.loginText {
  font-family: Mulish;
  font-weight: 900;
  font-size: 45px;
  text-align: center;
  min-width: 387px;
  margin-bottom: 33px;
  margin-top: 10px;
  color: #000;
}
