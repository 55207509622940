.my-circle{
    background-color: #18AA1B9A;
    border-radius: 50px;
    height:20px;
    width:20px;
    border:1px solid green;
    color:white;
    font-size: 13px;
}
.my-line{
    height: 1px;
    width: 5px;
    background-color: green;
    margin: 13px 0px 13px 0px;
}
.stat-container{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size:10px;
}
.oo{
    margin: 2px 0px -1px -10px;
}

.my-circle-red{
    background-color: #aa18189a;
    border-radius: 50px;
    height: 20px;
    width: 20px;
    color: white;
    border: 1px solid red;
}

.my-circle-gray{
    background-color: #a3a3a3fc;
    border-radius: 50px;
    height: 20px;
    width: 20px;
    color: white;
    border: 1px solid #979797;
    font-size: 13px;
}