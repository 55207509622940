@import url("https://fonts.googleapis.com/css2?family=Mulish&display=swap");

.ninja {
  background-color: #fafafa;
  width: 100%;
  min-height: 600px;
  font-family: Mulish, Helvetica, sans-serif;
  text-transform: none;
  letter-spacing: 1px;
}

.ninja_page {
  background-color: #fff;
  width: 100%;
  min-height: 600px;
}
.ninja_row1 {
  padding: 0px 60px;
  border: 2px solid #efeeef;
}

/* .n_p1 {
  text-align: center;
  font-size: 24px;
  color: #876bf5;
  padding: 10px 0px 0px 0px;
  text-transform: capitalize;
} */

.nin_box {
  border: 1px solid lightgray;
  border-radius: 5px;
  display: flex;
  max-width: 130px;
  padding: 5px;
  letter-spacing: 1px;
}
.nin_row1 {
  margin-top: 30px;
  margin-left: 20px;
}

p.nin_p1 {
  font-size: 11px;
  margin: auto;
  font-weight: 600;
  opacity: 0.7;
}

p.nin_p2 {
  color: #876bf5;
  font-size: 16px;
  margin: 0px;
  margin-left: 4px;
}

.nin_row2 {
  display: flex;
  justify-content: space-around;
  margin: 20px;
}

.nin_cards {
  border-radius: 8px;
  padding: 15px 0px;
  width: 28%;
  box-shadow: 0px 0px 10px 2px rgb(202 193 193);
  text-align: center;
}

p.nin_p3 {
  font-size: 15px;
  margin: 5px;
  color: #fff;
}

p.nin_p4 {
  font-size: 30px;
  color: #fff;
  margin: 8px;
  font-weight: 700;
}

/* p.nin_p5 {
  font-size: 14px;
  margin: 5px;
  color: white;
} */

.nin_col1 {
  width: 27%;
  margin-top: 22px;
  border-radius: 10px;
  box-shadow: 0px 0px 7px lightgrey;
  padding: 18px;
  height: 350px;
}
/* p.nin_p6 {
  color: #876bf5;
  font-size: 14px;
  text-align: left;
} */

.n_row1 {
  display: flex;
  justify-content: space-between;
}

p.nin_p7 {
  font-size: 12px;
  margin: 0px;
  margin-left: 10px;
  margin-top: 6px;
  opacity: 0.6;
}

p.nin_p8 {
  font-size: 10px;
  margin: 0px 8px;
  opacity: 0.8;
  color: black;
  cursor: pointer;
}

img.nin_filter {
  width: 12px;
  height: 15px;
}

.nin_table {
  background: #fafafa;
  border-radius: 10px;
  box-shadow: 1px 1px 7px lightgrey;
  /* width: 100%; */
  padding: 20px;
  margin-top: 20px;
}

.nin_footer {
  display: flex;
}

p.nin_p9 {
  font-size: 10px;
  color: #876bf5;
  margin: 5px;
}

p.nin_p10 {
  font-size: 12px;
  margin: 3px 0px;
}

p.nin_p11 {
  font-size: 12px;
  margin: 3px 10px;
}
.n_t_row1 {
  display: flex;
  width: 100%;
  font-size: 13px;
  font-weight: 600;
  color: #876bf5;
  justify-content: center;
  align-items: center;
}

.nin_th2,
.nin_th {
  font-size: 12px;
  font-weight: 600;
  color: #876bf5;
  flex: 0.4 1;
  display: flex;
  align-items: end;
  text-align: center;
  justify-content: center;
}

p.nin_th2 {
  flex: 0.3;
}

.nin_th1 {
  display: flex;
  justify-content: flex-end;
  flex: 0.1;
}
.nin_download {
  height: 40px;
  width: 40px;
}

.n_t_row2 {
  background: #fff;
  display: -webkit-flex;
  display: flex;
  border-radius: 6px;
  width: 100%;
  border: 1px solid #d3d3d3;
  margin-bottom: 12px;
  text-align: center;
  font-size: 12px;
  justify-content: center;
  align-items: center;
}
.nin_td1,
.nin_td2,
.nin_td3,
.nin_td4,
.nin_td5 {
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin: 0px;
  flex: 0.1;
}

p.nin_td1 {
  flex: 0.2;
}

.nin_td2 {
  font-weight: 600;
}

.nin_td3 {
  font-weight: 300;
}

.nin_td4 {
  color: #61bc88;
}

.nin_td5 {
  color: olivedrab;
}

.nin_card1 {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 1px 1px 10px lightgrey;
  border-radius: 8px;
  font-size: 9px;
  padding: 14px 18px;
  position: absolute;
  margin-top: 16px;
  margin-left: -152px;
  color: #876bf5;
  z-index: 2;
  right: 10%;
  min-height: 145px;
}

p.nin_from {
  margin: 0px;
  margin-right: 22px;
  margin-top: 5px;
}
.ncard_row1 {
  display: flex;
}

.ncard_row2 {
  display: flex;
  justify-content: flex-end;
}

p.nin_download_b {
  background: #876bf5;
  width: 35%;
  color: #fff;
  text-align: center;
  padding: 8px 16px;
  font-size: 12px;
  border-radius: 6px;
  box-sizing: border-box;
  box-shadow: 1px 1px 10px lightgrey;
  margin: 0px;
  margin-top: 52px;
  cursor: pointer;
}

.Rejected {
  color: #ff5d5d;
  background-color: #ff2e2e26;
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 5px;
  min-width: 90px;
  font-weight: 500;
  text-align: center;
}
.Completed,
.Approved {
  color: #45a315;
  background-color: #6ac13e38;
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 5px;
  min-width: 90px;
  font-weight: 500;
  text-align: center;
}
.Redo {
  background-color: #ffc23b4d;
  color: #f48d00;
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 5px;
  min-width: 90px;
  font-weight: 500;
  text-align: center;
}
.Pending {
  background-color: #ffd01959;
  color: #958b00;
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 5px;
  min-width: 90px;
  font-weight: 500;
  text-align: center;
}

.rideStatus {
  max-width: 182px;
  min-width: 182px;
  text-align: center;
}
.pAddStatus {
  min-width: 113px;
  max-width: 113px;
  text-align: center;
}
.myCity {
  max-width: 106px;
  min-width: 106px;
  text-align: center;
}
.vehicleNumber {
  min-width: 138px;
  max-width: 138px;
  text-align: center;
}
.driverContact {
  text-align: center;
  min-width: 122px;
  max-width: 122px;
}
.driverName {
  max-width: 164px;
  min-width: 164px;
  text-align: center;
}
.fseId {
  max-width: 91px;
  min-width: 91px;
  text-align: center;
}
.myDate {
  max-width: 74px;
  min-width: 74px;
  text-align: center;
}
.myLeadId {
  padding: 0px 0px 0px 20px;
  min-width: 59px;
  max-width: 59px;
  text-align: center;
}
.icon-container {
  display: flex;
  justify-content: flex-end;
}

.icon-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.search-container {
  display: flex;
  justify-content: center;
  border: 1px solid gray;
  border-radius: 10px;
  height: 32px;
  align-items: center;
  background: white;
}
.searchInput {
  border: none;
  border-radius: 10px;
  margin-left: 10px;
  outline: none;
}
.searchInput:focus {
  border: none;
  border-radius: 10px;
  margin-left: 10px;
  outline: none;
}

/* button.logout-btn {
  border-radius: 7px;
  background-color: lightgray;
  margin-left: 1000px;
  margin-top: -77px;
  padding: 6px 26px;
  color: #3b5bce;
  outline: none;
  border: none;
  box-shadow: 0 0 10px -3px #d3d3d3;
} */
.add-logout-style {
  display: flex;
  align-items: center;
  justify-content: center;
}
.fseName {
  min-width: 150px;
  max-width: 150px;
  text-align: center;
}
.fseContact {
  text-align: center;
  min-width: 87px;
  max-width: 87px;
}
.options {
  position: absolute;
  background: white;
  overflow: overlay;
  padding: 11px;
  box-shadow: 0px 0px 10px 3px lightgrey;
  border-radius: 10px;
}
.option-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: 0.8;
}
.option-item-text {
  font-size: 11px;
}
.option-item:hover {
  opacity: 1;
}
.result-count {
  padding: 5px 10px;
  border-radius: 10px;
  background: #77bd83;
  margin: 0px 10px 0px 0px;
  box-shadow: 0px 0px 9px 0px #c4c4c4;
  font-weight: bold;
  color: white;
}

.filterText {
  margin-right: 10px;
  font-size: 13px;
}
.load {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
}
.load-item {
  margin: 10px;
}
.charts-row {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 20px;
}

.clientStyleWidth {
  width: 63%;
}
.adminStyleWidth {
  width: 35%;
}

.load-message {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

/*---------------------------------------------------------------------------------------------------*/
.bijnis_cards {
  background-size: contain;
  background-repeat: no-repeat;
  width: 260px;
  height: 145px;
  background-color: #fff;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
p.bijnis_p3 {
  font-size: 14px;
  font-weight: 800;
}

p.bijnis_p4 {
  font-size: 30px;
  margin: 0;
  color: #fff;
  font-weight: 900;
}

button.btn.btn-secondary {
  border: 1px solid #dd6a57 !important;
  border-radius: 5px;
  background: #ffda74 !important;
}
p.nin_p6 {
  color: #121212;
  font-size: 14px;
  text-align: left;
}
.bijnis_row1 {
  padding: 0px 60px;
  border: 2px solid #efeeef;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logout-container {
  background-size: contain;
  background-repeat: no-repeat;
  width: 120px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

p.bijnis_p1 {
  margin: 0;
  margin-left: 18px;
  font-size: 14px;
  font-weight: 700;
  color: #855bf5;
}

.n_p1 {
  text-align: center;
  font-size: 24px;
  color: #f4792a;
  padding: 10px 0px 0px 0px;
  text-transform: capitalize;
  padding-left: 126px;
  font-weight: 600;
}
p.nin_p5 {
  font-size: 14px;
  margin: 0;
  padding: 5px 8px;
  border-radius: 5px;
  font-weight: 600;
  color: #121212;
  background: linear-gradient(to right, #fff1d6, #f0c163, transparent);
}
