@import url(https://fonts.googleapis.com/css2?family=Mulish&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.ninja {
  background-color: #fafafa;
  width: 100%;
  min-height: 600px;
  font-family: Mulish, Helvetica, sans-serif;
  text-transform: none;
  letter-spacing: 1px;
}

.ninja_page {
  background-color: #fff;
  width: 100%;
  min-height: 600px;
}
.ninja_row1 {
  padding: 0px 60px;
  border: 2px solid #efeeef;
}

/* .n_p1 {
  text-align: center;
  font-size: 24px;
  color: #876bf5;
  padding: 10px 0px 0px 0px;
  text-transform: capitalize;
} */

.nin_box {
  border: 1px solid lightgray;
  border-radius: 5px;
  display: flex;
  max-width: 130px;
  padding: 5px;
  letter-spacing: 1px;
}
.nin_row1 {
  margin-top: 30px;
  margin-left: 20px;
}

p.nin_p1 {
  font-size: 11px;
  margin: auto;
  font-weight: 600;
  opacity: 0.7;
}

p.nin_p2 {
  color: #876bf5;
  font-size: 16px;
  margin: 0px;
  margin-left: 4px;
}

.nin_row2 {
  display: flex;
  justify-content: space-around;
  margin: 20px;
}

.nin_cards {
  border-radius: 8px;
  padding: 15px 0px;
  width: 28%;
  box-shadow: 0px 0px 10px 2px rgb(202 193 193);
  text-align: center;
}

p.nin_p3 {
  font-size: 15px;
  margin: 5px;
  color: #fff;
}

p.nin_p4 {
  font-size: 30px;
  color: #fff;
  margin: 8px;
  font-weight: 700;
}

/* p.nin_p5 {
  font-size: 14px;
  margin: 5px;
  color: white;
} */

.nin_col1 {
  width: 27%;
  margin-top: 22px;
  border-radius: 10px;
  box-shadow: 0px 0px 7px lightgrey;
  padding: 18px;
  height: 350px;
}
/* p.nin_p6 {
  color: #876bf5;
  font-size: 14px;
  text-align: left;
} */

.n_row1 {
  display: flex;
  justify-content: space-between;
}

p.nin_p7 {
  font-size: 12px;
  margin: 0px;
  margin-left: 10px;
  margin-top: 6px;
  opacity: 0.6;
}

p.nin_p8 {
  font-size: 10px;
  margin: 0px 8px;
  opacity: 0.8;
  color: black;
  cursor: pointer;
}

img.nin_filter {
  width: 12px;
  height: 15px;
}

.nin_table {
  background: #fafafa;
  border-radius: 10px;
  box-shadow: 1px 1px 7px lightgrey;
  /* width: 100%; */
  padding: 20px;
  margin-top: 20px;
}

.nin_footer {
  display: flex;
}

p.nin_p9 {
  font-size: 10px;
  color: #876bf5;
  margin: 5px;
}

p.nin_p10 {
  font-size: 12px;
  margin: 3px 0px;
}

p.nin_p11 {
  font-size: 12px;
  margin: 3px 10px;
}
.n_t_row1 {
  display: flex;
  width: 100%;
  font-size: 13px;
  font-weight: 600;
  color: #876bf5;
  justify-content: center;
  align-items: center;
}

.nin_th2,
.nin_th {
  font-size: 12px;
  font-weight: 600;
  color: #876bf5;
  flex: 0.4 1;
  display: flex;
  align-items: end;
  text-align: center;
  justify-content: center;
}

p.nin_th2 {
  flex: 0.3 1;
}

.nin_th1 {
  display: flex;
  justify-content: flex-end;
  flex: 0.1 1;
}
.nin_download {
  height: 40px;
  width: 40px;
}

.n_t_row2 {
  background: #fff;
  display: flex;
  border-radius: 6px;
  width: 100%;
  border: 1px solid #d3d3d3;
  margin-bottom: 12px;
  text-align: center;
  font-size: 12px;
  justify-content: center;
  align-items: center;
}
.nin_td1,
.nin_td2,
.nin_td3,
.nin_td4,
.nin_td5 {
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin: 0px;
  flex: 0.1 1;
}

p.nin_td1 {
  flex: 0.2 1;
}

.nin_td2 {
  font-weight: 600;
}

.nin_td3 {
  font-weight: 300;
}

.nin_td4 {
  color: #61bc88;
}

.nin_td5 {
  color: olivedrab;
}

.nin_card1 {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 1px 1px 10px lightgrey;
  border-radius: 8px;
  font-size: 9px;
  padding: 14px 18px;
  position: absolute;
  margin-top: 16px;
  margin-left: -152px;
  color: #876bf5;
  z-index: 2;
  right: 10%;
  min-height: 145px;
}

p.nin_from {
  margin: 0px;
  margin-right: 22px;
  margin-top: 5px;
}
.ncard_row1 {
  display: flex;
}

.ncard_row2 {
  display: flex;
  justify-content: flex-end;
}

p.nin_download_b {
  background: #876bf5;
  width: 35%;
  color: #fff;
  text-align: center;
  padding: 8px 16px;
  font-size: 12px;
  border-radius: 6px;
  box-sizing: border-box;
  box-shadow: 1px 1px 10px lightgrey;
  margin: 0px;
  margin-top: 52px;
  cursor: pointer;
}

.Rejected {
  color: #ff5d5d;
  background-color: #ff2e2e26;
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 5px;
  min-width: 90px;
  font-weight: 500;
  text-align: center;
}
.Completed,
.Approved {
  color: #45a315;
  background-color: #6ac13e38;
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 5px;
  min-width: 90px;
  font-weight: 500;
  text-align: center;
}
.Redo {
  background-color: #ffc23b4d;
  color: #f48d00;
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 5px;
  min-width: 90px;
  font-weight: 500;
  text-align: center;
}
.Pending {
  background-color: #ffd01959;
  color: #958b00;
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 5px;
  min-width: 90px;
  font-weight: 500;
  text-align: center;
}

.rideStatus {
  max-width: 182px;
  min-width: 182px;
  text-align: center;
}
.pAddStatus {
  min-width: 113px;
  max-width: 113px;
  text-align: center;
}
.myCity {
  max-width: 106px;
  min-width: 106px;
  text-align: center;
}
.vehicleNumber {
  min-width: 138px;
  max-width: 138px;
  text-align: center;
}
.driverContact {
  text-align: center;
  min-width: 122px;
  max-width: 122px;
}
.driverName {
  max-width: 164px;
  min-width: 164px;
  text-align: center;
}
.fseId {
  max-width: 91px;
  min-width: 91px;
  text-align: center;
}
.myDate {
  max-width: 74px;
  min-width: 74px;
  text-align: center;
}
.myLeadId {
  padding: 0px 0px 0px 20px;
  min-width: 59px;
  max-width: 59px;
  text-align: center;
}
.icon-container {
  display: flex;
  justify-content: flex-end;
}

.icon-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.search-container {
  display: flex;
  justify-content: center;
  border: 1px solid gray;
  border-radius: 10px;
  height: 32px;
  align-items: center;
  background: white;
}
.searchInput {
  border: none;
  border-radius: 10px;
  margin-left: 10px;
  outline: none;
}
.searchInput:focus {
  border: none;
  border-radius: 10px;
  margin-left: 10px;
  outline: none;
}

/* button.logout-btn {
  border-radius: 7px;
  background-color: lightgray;
  margin-left: 1000px;
  margin-top: -77px;
  padding: 6px 26px;
  color: #3b5bce;
  outline: none;
  border: none;
  box-shadow: 0 0 10px -3px #d3d3d3;
} */
.add-logout-style {
  display: flex;
  align-items: center;
  justify-content: center;
}
.fseName {
  min-width: 150px;
  max-width: 150px;
  text-align: center;
}
.fseContact {
  text-align: center;
  min-width: 87px;
  max-width: 87px;
}
.options {
  position: absolute;
  background: white;
  overflow: overlay;
  padding: 11px;
  box-shadow: 0px 0px 10px 3px lightgrey;
  border-radius: 10px;
}
.option-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: 0.8;
}
.option-item-text {
  font-size: 11px;
}
.option-item:hover {
  opacity: 1;
}
.result-count {
  padding: 5px 10px;
  border-radius: 10px;
  background: #77bd83;
  margin: 0px 10px 0px 0px;
  box-shadow: 0px 0px 9px 0px #c4c4c4;
  font-weight: bold;
  color: white;
}

.filterText {
  margin-right: 10px;
  font-size: 13px;
}
.load {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
}
.load-item {
  margin: 10px;
}
.charts-row {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 20px;
}

.clientStyleWidth {
  width: 63%;
}
.adminStyleWidth {
  width: 35%;
}

.load-message {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

/*---------------------------------------------------------------------------------------------------*/
.bijnis_cards {
  background-size: contain;
  background-repeat: no-repeat;
  width: 260px;
  height: 145px;
  background-color: #fff;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
p.bijnis_p3 {
  font-size: 14px;
  font-weight: 800;
}

p.bijnis_p4 {
  font-size: 30px;
  margin: 0;
  color: #fff;
  font-weight: 900;
}

button.btn.btn-secondary {
  border: 1px solid #dd6a57 !important;
  border-radius: 5px;
  background: #ffda74 !important;
}
p.nin_p6 {
  color: #121212;
  font-size: 14px;
  text-align: left;
}
.bijnis_row1 {
  padding: 0px 60px;
  border: 2px solid #efeeef;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logout-container {
  background-size: contain;
  background-repeat: no-repeat;
  width: 120px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

p.bijnis_p1 {
  margin: 0;
  margin-left: 18px;
  font-size: 14px;
  font-weight: 700;
  color: #855bf5;
}

.n_p1 {
  text-align: center;
  font-size: 24px;
  color: #f4792a;
  padding: 10px 0px 0px 0px;
  text-transform: capitalize;
  padding-left: 126px;
  font-weight: 600;
}
p.nin_p5 {
  font-size: 14px;
  margin: 0;
  padding: 5px 8px;
  border-radius: 5px;
  font-weight: 600;
  color: #121212;
  background: linear-gradient(to right, #fff1d6, #f0c163, transparent);
}

.my-circle{
    background-color: #18AA1B9A;
    border-radius: 50px;
    height:20px;
    width:20px;
    border:1px solid green;
    color:white;
    font-size: 13px;
}
.my-line{
    height: 1px;
    width: 5px;
    background-color: green;
    margin: 13px 0px 13px 0px;
}
.stat-container{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size:10px;
}
.oo{
    margin: 2px 0px -1px -10px;
}

.my-circle-red{
    background-color: #aa18189a;
    border-radius: 50px;
    height: 20px;
    width: 20px;
    color: white;
    border: 1px solid red;
}

.my-circle-gray{
    background-color: #a3a3a3fc;
    border-radius: 50px;
    height: 20px;
    width: 20px;
    color: white;
    border: 1px solid #979797;
    font-size: 13px;
}
.table_data {
  display: flex;
  justify-content: center;
}

.card_table {
  background: #f2f2f2;
  box-shadow: 5px 5px 30px #dededebf;
  border-radius: 20px;
  width: 100%;
  padding: 20px;
}

.sc-hKFxyN.sc-eCApnc.sc-iqAclL.kXbQov.jFwrzr.rdt_TableCol {
  color: #855bf5;
  /* font-size: 13px; */
  /* font-weight: 800 !important; */
}

.sc-jrsJWt.flbcPw.rdt_TableRow,
.sc-jrsJWt.hztQPW.rdt_TableRow {
  background: #fff;
  border-radius: 10px;
  margin: 2px 0;
  padding: 10px 0;
  justify-content: center;
  border: 1px solid lightgray;
}

div#cell-6-undefined,
div#cell-3-undefined,
div#cell-1-undefined {
  color: #252733;
  font-weight: 600;
}

div#cell-5-undefined,
div#cell-7-undefined,
div#cell-2-undefined {
  color: #5c5c5c;
  font-size: 11px;
  font-weight: 500;
}

div#cell-4-undefined {
  color: #5c5c5c;
  font-size: 12px;
  font-weight: 500;
}

.data-table-extensions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  float: right;
  margin-right: 45px;
}

button.print,
button.download {
  display: none;
}

button {
  border: none;
  background: rgb(98, 84, 156);
  color: #fff;
  padding: 4px 15px;
  border-radius: 5px;
  margin: 0 8px;
}

input.filter-text {
  border: 1px solid #b2b2b2;
  border-radius: 5px;
  padding: 4px 15px;
  font-size: 14px;
}

input.filter-text:focus {
  outline: none;
}

.menu-container {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: -37px;
}

.rdt_TableRow {
  background-color: white;
}

/*---------------------------------------------------------------------------------------------------------------*/
.Footware {
  color: #f4792a;
  background: #f4792a15;
  padding: 5px 10px;
  border-radius: 5px;

}

.Clothes {
  color: #dba800;
  background: #ffc10017;
  padding: 5px 10px;
  border-radius: 5px;
}

div#column-9 {
  color: #f4792a;
}

div#column-10 {
  color: #dba800;
}

.model_contain {
  overflow: scroll;
  margin: 60px 90px;
  height: 100vh;

}

.call_box {
  overflow: scroll;
  display: flex;
  flex-wrap: wrap;
  background: #fff;
  border-radius: 12px;
}

.modal_call {
  overflow: scroll;
  margin-top: 200px;
}

img.profile_image {
  width: 100%;
  height: 200px;
}

.lead_id {
  float: left;
  color: #292c5c;
  font-size: 14px;
  margin: 5px;
}

.call_ringing {
  width: 22%;
  margin: 30px 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.threedots:after {
  content: "\2807";
  font-size: 30px;
  color: #9e81fb;
}
.dropdown-menu.show {
  margin-left: -53px;
  margin-top: -12px;
  font-size: 12px;
  color: #855bf5 !important;
}
.dropdown-item {
  /* display: block; */
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #855bf5 !important;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center;
}
img.drop_image_download {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

div#form_date_checkin_picker {
  position: absolute;
  /* width: 230px; */
  background: #fff;
  /* height: 100px; */
  margin-left: -315px;
  z-index: 5;
  margin-top: -18px;
  box-shadow: 10px 15px 20px #b7b7b741;
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  padding: 18px 25px;
}

.title_download {
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  margin-left: 0;
  display: flex;
  justify-content: space-between;
}
.subtitle_download {
  font-size: 10px;
  color: #b2b2b2;
  margin: 5px;
  text-align: left;
  margin-left: 0;
}
.input_row_download {
  display: flex;
  margin: 0;
  justify-content: space-between;
}
input.input_download {
  border: none;
  border-bottom: 2px solid #c1aaff;
  margin: 10px;
  font-size: 11px;
  margin-left: 0;
}
.input_download::-webkit-input-placeholder {
  color: #ded1ff;
}
.input_download:-ms-input-placeholder {
  color: #ded1ff;
}
.input_download::placeholder {
  color: #ded1ff;
}
select.select_download {
  width: 38%;
  height: 30px;
  margin: 5px;
  border: 1px solid #c1aaff;
  border-radius: 5px;
  padding: 5px;
}
p.download_p1 {
  width: 70px;
  display: flex;
}
/* .button_download {
  background: #855bf5 0% 0% no-repeat padding-box;
  border-radius: 5px;
  color: #fff;
  width: 40%;
  font-size: 10px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
} */

.flex_download {
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
}

img.close_button {
  cursor: pointer;
  margin-right: -12px;
  margin-top: -22px;
}

div#modal_full_upload {
  position: fixed;
  top: 0%;
  left: 0%;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  width: 100%;
  height: 800px;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal_card_upload {
  background: #fff;
  box-shadow: 10px 15px 30px #00000029;
  border: 1px solid #707070;
  border-radius: 30px;
  width: 70%;
  height: 580px;
  display: flex;
  justify-content: center;
  align-items: center;
}
img.document_upload_image {
  width: 60px;
  height: 60px;
}
p.modal_text_p1 {
  margin: 8px;
}

.button_download_modal {
  background: #855bf5 0% 0% no-repeat padding-box;
  border-radius: 5px;
  color: #fff;
  width: 55%;
  font-size: 10px;
  margin: auto;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}

img.close_button_upload {
  position: absolute;
  right: 17%;
  cursor: pointer;
  top: 15%;
  width: 45px;
  height: 85px;
}

a.click_browse {
  text-decoration: underline;
  cursor: pointer;
}
p.model_text_p3 {
  /* border: 1px solid #855bf5; */
  border-radius: 30px;
  padding: 8px;
  background: #fafafa;
  margin: 15px 0;
  font-size: 11px;
}
p.modal_text_p4 {
  margin-top: 105px;
  font-size: 15px;
}
p.modal_text_p2 {
  font-size: 12px;
  margin: 10px;
  color: #aaaaaa;
  margin-top: -12px;
}

.MuiIconButton-root {
  flex: 0 0 auto;
  color: rgba(0, 0, 0, 0.54);
  padding: 1px !important;
  overflow: visible;
  font-size: 1.5rem;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
}

.MuiInputBase-input {
  font: inherit;
  color: currentColor;
  width: 100%;
  border: 0;
  height: 1.1876em;
  margin: 0;
  display: block;
  padding: 6px 0 7px;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  -webkit-animation-name: mui-auto-fill-cancel;
          animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
  -webkit-animation-duration: 10ms;
          animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent;
  font-size: 10px !important;
}

.city_row_download {
  display: flex;
  font-size: 12px;
  color: #ded1ff;
  align-items: center;
  margin-top: 10px;
}
.jss2,
.makeStyles-textField-12,
.makeStyles-textField-10,
.makeStyles-textField-9,
.makeStyles-textField-11,
.makeStyles-textField-7,
.makeStyles-textField-3,
.makeStyles-textField-5,
.makeStyles-textField-2,
.makeStyles-textField-1,
.makeStyles-textField-4,
.makeStyles-textField-8,
.makeStyles-textField-6 {
  width: 122px !important;
  margin-left: 8px;
  margin-right: 8px;
}
.button_download {
  background: #855bf5 0% 0% no-repeat padding-box;
  border-radius: 5px;
  color: #fff;
  width: 40%;
  font-size: 10px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.flex_download1 {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}
.button_download1 {
  background: #855bf5 0% 0% no-repeat padding-box;
  border-radius: 5px;
  color: #fff;
  width: 43%;
  font-size: 10px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

a {
  color: #fff !important;
}
div#form_date_picker_client,
div#form_date_picker {
  position: absolute;
  width: 330px !important;
  background: #fff;
  /* height: 100px; */
  margin-left: -315px;
  z-index: 5;
  margin-top: -18px;
  box-shadow: 10px 15px 20px #b7b7b741;
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  padding: 18px 25px;
}

.threedots:after {
    content: "\2807";
    min-height: "100px";
    font-size: 30px;
    color: #9e81fb;
  }
  
  button.btn.btn-secondary {
    font-size: 10px;
    color: black;
    /* background-image: url("FVector.svg"); */
    background-position: center;
    background-color: white;
    background-repeat: no-repeat;
    /* padding: 9px 26px; */
  }
  /* button.btn.btn-secondary:focus {
    outline: none;
    border: none;
  } */
  .btn-secondary.focus,
  .btn-secondary:focus {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
    box-shadow: 0 0 0 0rem rgb(0 0 0 / 100%) !important;
  }
  
.dropdown-container{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top:-42px;
    background-color: white;
    font-size:10px;
}

.dropdown-container{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top:-42px;
    background-color: white;
    font-size:10px;
}
.dropdown-container{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top:-42px;
    background-color: white;
    font-size:10px;
}
.login {
  background-color: #fff;
  width: 100%;
  height: 100%;
}

.bg_img {
  /* width: 65%;
    z-index: 0;
    margin-top: 120px; */
  height: 600px;
  width: 700px;
  margin-right: 90px;
}

h1 {
  color: #fff;
}

.login_card {
  display: flex;
  background: #fff;
  position: absolute;
  right: 3%;
  top: 8%;
  bottom: 5%;
  left: 74%;
  align-items: center;
  align-content: center;
  justify-content: center;
  border-radius: 30px;
  z-index: 5;
  flex-direction: column;
}

.login_button {
  background: white;
  border: #22a7cb 2px solid;
  display: flex;
  justify-content: center;
  width: 250px;
  height: 45px;
  box-sizing: border-box;
  box-shadow: 2px 4px 17px -5px rgb(0 0 0 / 20%);
  border-radius: 9px;
  text-align: center;
}
.login_button:hover {
  cursor: pointer;
}

img.login_img {
  width: 45px;
  height: 45px;
}

p.login_p {
  margin: auto;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  color: #22a7cb;
}

.login_p1 {
  position: absolute;
  left: 10.68%;
  top: 8.83%;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 72px;
  letter-spacing: 0.5px;
  color: #ffffff;
  width: 50%;
  z-index: 5;
}

.App1 {
  font-family: Mulish;
  background-color: #fcfcff;
}
.login-container {
  display: flex;
  justify-content: center;
  /* flex-direction: column; */
  align-items: center;
  height: 100vh;
  background-color: #ffc303;
}

.logo-img {
  height: 500px;
}
.btn-block-container {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.loginText {
  font-family: Mulish;
  font-weight: 900;
  font-size: 45px;
  text-align: center;
  min-width: 387px;
  margin-bottom: 33px;
  margin-top: 10px;
  color: #000;
}


